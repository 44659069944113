import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'

import Modal from 'react-modal'
import Button from 'react-bootstrap/Button'

import ImageGallery from '../components/image-gallery'
import Layout from '../components/layout'

export default class ProductTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showModal: false }
  }
  render() {
    const product = get(this, 'props.data.contentfulProduct')
    const price = `$${~~(product.price / 100)}.${(product.price % 100)
      .toString()
      .padStart(2, 0)}`
    // allow auto meta description
    return (
      <Layout pageTitle={product.title}>
        {product.images && this.state.showModal && (
          <Modal
            isOpen={this.state.showModal}
            onRequestClose={_ => this.setState({ showModal: false })}
            ariaHideApp={false}
          >
            <h2>{product.title}</h2>
            <ImageGallery images={product.images} maxHeight="75vh" />
            <Button
              onClick={_ => this.setState({ showModal: false })}
              style={{ position: 'absolute', right: '1rem', top: '1rem' }}
            >
              <i className="fa fa-times" />
            </Button>
            <Button
              variant="outline-primary mt-2"
              onClick={_ => this.setState({ showModal: false })}
            >
              Close
            </Button>
          </Modal>
        )}

        <div className="d-flex justify-content-between product">
          {product.images && (
            <div className="p-3 product__gallery">
              <ImageGallery images={product.images} maxHeight="50vh" />
              {product.images.length > 1 && (
                <Button
                  variant="light w-100"
                  onClick={_ => this.setState({ showModal: true })}
                >
                  <i className="fa fa-image"></i>&nbsp; View Gallery
                </Button>
              )}
            </div>
          )}
          <div className="p-3">
            <h1>{product.title}</h1>
            <p className="lead mb-0">{price}</p>
            <p style={{ fontSize: '.75rem' }}>+ tax/shipping</p>
            {product.checkoutLink ? (
              <Button href={product.checkoutLink}>
                <i className="fa fa-cart-plus"></i>
                {` `}Add to Cart
              </Button>
            ) : (
              <Button as={Link} to="/contact/">
                Contact Us
              </Button>
            )}
          </div>
        </div>

        <hr />

        {product.description && (
          <>
            <h2>Description</h2>
            <div
              className="product__description"
              dangerouslySetInnerHTML={{
                __html: product.description.childMarkdownRemark.html,
              }}
            />
          </>
        )}

        <hr />

        <h2>Customer Service</h2>
        <Button as={Link} to="/customer-service/">
          Contact Support
        </Button>
      </Layout>
    )
  }
}

export const Query = graphql`
  query ProductBySlug($slug: String!) {
    contentfulProduct(slug: { eq: $slug }) {
      slug
      price
      title
      checkoutLink
      images {
        title
        fixed(quality: 100, width: 1000) {
          src
          srcSet
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
